import { React, useState } from 'react'
import { Carousel } from '@material-tailwind/react'
import Footer from '../components/Footer'

function Home() {
  const style = {
    wrapper: 'px-12 py-24',
    left: 'sm:w-5/12',
    right: 'sm:w-4/12 w-10/12 mt-8',
    middle:
      'sm:flex sm:flex-wrap sm:space-x-4 items-center mt-4  flex flex-row space-x-4',
    lhead:
      'sm:mb-6 sm:text-white sm:w-3/4 sm:text-5xl sm:font-bold sm:font-nunito w-full font-nunito text-2xl font-bold mb-2',
    mtitle:
      'sm:text-xl mr-4  sm:text-midnight sm:leading-5 sm:font-nunito text-xs text-midnight leading-2 font-nunito font-bold',
    ltitle:
      'sm:mb-8 sm:text-[14px] sm:text-white sm:leading-5 sm:font-nunito text-[8px] w-3/4 text-white leading-2 font-nunito',
    icon: 'sm:h-4 sm:w-4 sm:ml-2 h-2 w-2 ml-2',
    callicon: 'sm:h-32 sm:w-48 sm:ml-2 h-12 w-18 ',
    lbottomitem:
      'sm:rounded-lg sm:bg-[#B8E5EC] sm:w-28 sm:py-1.5 sm:px-2 sm:font-nunito sm:font-bold sm:text-[10px] sm:text-midnight sm:items-center sm:flex sm:justify-between     rounded-lg bg-[#B8E5EC] w-18 py-1 px-2 font-nunito font-bold text-[8px] text-midnight items-center flex justify-between',
  }

  const images = [
    {
      src: 'p6.jpeg',
      alt: 'image 1',
    },
    {
      src: 'p5.jpeg',
      alt: 'image 2',
    },
    {
      src: 'p1.jpeg',
      alt: 'image 3',
    },
    {
      src: 'p4.jpeg',
      alt: 'image 1',
    },
    {
      src: 'p2.jpeg',
      alt: 'image 2',
    },
    {
      src: 'p3.jpeg',
      alt: 'image 3',
    },
  ]

  const [showCarousel, setShowCarousel] = useState(true)
  return (
    <>
      {showCarousel ? (

        <div>
 <Carousel
          className="h-4/5 w-full"
          infinite
          transition={{ duration: 2 }}
          autoplay
          interval={1000}
          navigation={({ setActiveIndex, activeIndex, length }) => (
            <div className="absolute  bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2 ">
              {new Array(length).fill('').map((_, i) => (
                <span
                  key={i}
                  className={`block mt-24 h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                    activeIndex === i
                      ? 'bg-midnight w-12 h-2'
                      : 'bg-[#D9D9D9]/50 w-2 h-2'
                  }`}
                  onClick={() => setActiveIndex(i)}
                />
              ))}
            </div>
          )}
        >
          {images.map((image, index) => (
            <div key={index} className="bg-midnight">
              <img
                src={image.src}
                alt=""
                className="rounded-2xl px-4  w-full h-screen object-cover mb-12"
              />
            </div>
          ))}
        </Carousel>
        </div>
       
      ) : (
        <div className="text-center">
          {/* Add your text content here */}
          <h1>Text Content</h1>
          <p>Some description...</p>
        </div>
      )}

      <div className=" sm:flex sm:flex-row   sm:justify-between sm:h-98 sm:items-center sm:absolute sm:top-1/2 sm:left-1/2 sm:transform sm:-translate-x-1/2 sm:-translate-y-1/2 sm:w-full sm:px-24           flex flex-col justify-between  items-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full px-4 py-4 mt-8">
        <div className="sm:bg-[#000] sm:bg-opacity-[0.2] sm:rounded-xl sm:p-8 sm:w-6/12  bg-[#000] bg-opacity-[0.2] rounded-xl p-8 ">
          <h1 className={style.lhead}>Trust us to keep your valuables</h1>
          <p className={style.ltitle}>
            Our facility entry and exit procedures have been designed to
            maximize protection of your valuables and irreplaceable documents.
          </p>

          <div className="flex flex-row items-center">
            <img className={style.callicon} src="rr.png" alt="" />
            <div className="flex flex-col items-start">
              <h1 className="sm:text-xl sm:font-nunito sm:font-bold sm:text-white text-lg font-nunito font-bold text-white">
                Call Us Now
              </h1>
              <div className="sm:flex sm:flex-row sm:justify-between sm:w-72 sm:bg-white sm:items-center sm:p-4 sm:rounded-lg sm:h-12    flex flex-row justify-between w-48 bg-white items-center p-4 rounded-lg h-8">
                <p className={style.mtitle}>08093323430</p>{' '}
                <p className={style.mtitle}>08181953758</p>
              </div>
            </div>
          </div>

          <div className={style.middle}>
            <div className={style.lbottomitem}>
              Secured
              <img className={style.icon} src="she.png" alt="" />
            </div>
            <div className={style.lbottomitem}>
              Privacy
              <img className={style.icon} src="sec.png" alt="" />
            </div>
            <div className={style.lbottomitem}>
              24 / 7 Service
              <img className={style.icon} src="time.png" alt="" />
            </div>
          </div>
          <div classname="flex flex-row"></div>
        </div>
        <div className={style.right}>
          <img className="w-full h-auto" src="Group 3.png" alt="" />
        </div>
      </div>

      <Footer />
    </>
  )
}

export default Home
