import React, { useState,useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { BiMenuAltRight } from 'react-icons/bi'
import { useDisplayNavState } from '././utils/stateFile';

function Navbar() {
  const { displayNav, setDisplayNav } = useDisplayNavState();

  const navData = [
    {
      title: 'Home',
      to: '',
    },
    {
      title: 'About',
      to: '/about',
    },
    {
      title: 'Services',
      to: '/services',
    },
    {
      title: 'Meet The Team',
      to: '/meet',
    },
    {
      title: 'Contact',
      to: '/contact',
    },
  ]

  const style = {
    wrapper:
      ' sm:px-16  sm:flex sm:flex-row sm:items-center sm:h-[3.5rem]  sm:bg-white sm:w-full px-4  flex items-center h-[3rem]  bg-midnight w-full  ',
    middle:
      'font-light text-sm text-silver space-x-12 justify-between sm:flex flex-col',
    nav: `sm:mx-auto fixed sm:relative   w-full  h-1/4 lg:w-auto  lg:h-auto top-0 left-0 duration-200 transition-all ease-in flex ${
      displayNav ? ' translate-x-0 ' : ' -translate-x-full lg:translate-x-0 '
    }`,
    navList:
      'sm:flex sm:items-center sm:bg-white sm:flex-row sm:h-auto sm:justify-center sm:mt-0 sm:shadow-none sm:rounded-none     flex flex-col  h-64 bg-white mt-16 px-4 rounded-xl shadow-xl justify-items-start',
    listItem: 'mx-[1rem]',
    logo: 'sm:w-14 w-12',
    icon: 'w-[1.5rem] h-[1.5rem]',
    right:
      'flex lg:ml-0 ml-auto rounded-lg p-1 font-nunito text-silver text-xs font-medium bg-midnight items-center',
    active: 'font-bold sm:text-midnight font-nunito py-[.5rem] lg:py-0 block text-midnight',
    menuIcon: 'lg:hidden w-[1.8rem] h-[1.8rem] ml-[1rem]',
    closeIcon:
      'lg:hidden w-[1.8rem] h-[1.8rem] ml-auto mt-[1rem] mr-[1rem] text-[#fff] ',
  }

  const toggleMenuNavHandler = () => {
    setDisplayNav((prevState) => !prevState)
  }



  useEffect(() => {
    let prevScrollPos = window.pageYOffset;

    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;

      if (currentScrollPos > prevScrollPos && displayNav) {
        setDisplayNav(false);
      }

      prevScrollPos = currentScrollPos;
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [displayNav]);



  return (
    <header className={style.wrapper}>
      <div className={style.logo}>
        <Link to="/">
          {' '}
          <img src="logo.png" alt="logo" />
        </Link>
      </div>
      <nav className={style.nav}>
        <ul className={style.navList}>
          {navData.map((nav, index) => (
            <li key={index} className={style.listItem}>
              <NavLink
                to={nav.to}
                onClick={toggleMenuNavHandler}
                className={(activeData) =>
                  activeData.isActive
                    ? style.active
                    : 'py-[.5rem] lg:py-0 block font-nunito text-silver'
                }
              >
                {nav.title}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
      <div className={style.right}>
        <span>#1 Best Vault </span>
        <img className={style.icon} src="badge.png" alt="logo" />
      </div>
      {!displayNav && (
        <BiMenuAltRight
          className={style.menuIcon}
          onClick={toggleMenuNavHandler}
        />
      )}
    </header>
  )
}

export default Navbar