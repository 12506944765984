import React from 'react'
import Footer from '../components/Footer'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import servicesData from '../components/utils/servicesData'

function Services() {
  const style = {
    wrapper:
      'sm:px-12 sm:py-16 sm:w-full sm:h-auto sn:bg-white sm:flex sm:flex-col sm:justify-evenly sm:items-center   px-8 py-4 w-full h-auto bg-white flex flex-col items-center  ',
    headerItem:
      ' sm:flex sm:flex-col sm:items-center sm:mb-24 flex flex-col items-center mb-12',
    header:
      'sm:pl-2 sm:text-4xl sm:font-nunito sm:font-bold sm:text-midnight  pl-2 text-lg font-nunito font-bold text-midnight',
    middle:
      ' sm:flex sm:flex-row sm:my-[3rem] sm:lg:my-0 sm:w-full sm:justify-center sm:gap-y-4 gap-y-[2rem] my-[3rem]',
    line: 'sm:w-64 w-32',
    title: 'font-nunito text-black text-xs font-bold pt-4',
    subtitle:
      'sm:font-nunito sm:text-black  sm:text-sm sm:w-2/4 sm:mt-4 sm:leading-6   leading-5 mt-4 text-xs text-center text-black font-nunito',
    mItems:
      'sm:flex sm:flex-col  sm:bg-[#E9F0F3]  sm:mx-[1.5rem] sm:rounded-lg sm:items-center  flex flex-col  bg-[#E9F0F3]  mx-12 my-12 rounded-lg items-center ',
    iheader:
      'sm:font-nunito sm:text-black sm:text-lg sm:font-bold sm:pt-4 font-nunito text-black text-sm font-bold pt-4',
    row: 'text-[#282938] text-base flex items-start items-center  w-full',
    icon: 'sm:w-48 sm:mt-16 sm:h-[10rem] w-32 mt-8 h-32',
    rowicon: 'sm:w-4 mr-[.5rem] w-2',
    ritext: 'sm:text-xs sm:w-72 text-[10px] w-72',
    btn: 'bg-midnight sm:text-sm font-nunito mb-8 mt-auto text-[10px]',
  }
  const data = [
    //crop the second image on the slide to get the this box
    {
      id: 1,
      imageSrc: 'small.jpeg',
      title: 'Small Box',
      dimensions: '20CM x 10CM x 40CM',
      description: 'Cheapest most basic package',
      additionalInfo:
        'Ideal for trinklets, Jewellery, medals and expensive stones/metals',
      buttonText: 'Rent this Small Box',
    },
    {
      id: 2,
      imageSrc: 'box.jpg',
      title: 'Medium Box',
      dimensions: '30CM x 10CM x 40CM',
      description: 'Value Added, most popular package',
      additionalInfo:
        'Ideal for legal documents, property deeds and titles, travel documents etc',
      buttonText: 'Rent this Medium Box',
    },
    {
      id: 3,
      imageSrc: 'medium.jpg',
      title: 'Large Box',
      dimensions: '30CM x 20CM x 40CM',
      description: 'The document safe house',
      additionalInfo: 'Ideal for large volume of valuable documents and items',
      buttonText: 'Rent this Large Box',
    },
    //Picture on the 4th slide
    {
      id: 4,
      imageSrc: 'Big.jpeg',
      title: 'Extra Large Box',
      dimensions: '40CM x 40CM x 40CM',
      description: 'The Vault Master',
      additionalInfo: 'Ideal for anything you throw at it; Volume or Value',
      buttonText: 'Rent this Jumbo Box',
    },
    {
      id: 5,
      imageSrc: 'huge.jpg',
      title: 'Jumbo Box',
      dimensions: '100CM x 50CM x 60CM',
      description: 'The Vault Master',
      additionalInfo: 'Ideal for anything you throw at it; Volume or Value',
      buttonText: 'Rent this Jumbo Box',
    },
  ]

  return (
    <>
      <div className={style.wrapper}>
        <div className={style.headerItem}>
          <span className={style.header}>Our Services</span>
          <img className={style.line} alt="" src="line.png"></img>
          <span className={style.subtitle}>
            Bulwark Vault & Safes Deposits rents out safe deposit boxes in its
            private vault in various sizes to individuals and businesses and
            corporations. Bulwark Vault and Safes Deposits Limited is a secure
            private Vault facility offering 24-hours accessibility located
            conveniently in the heart of Ikoyi, Lagos. Bulwark remains the first
            world class depository company in Lagos.
          </span>
        </div>
        <div className={style.middle}>
          {servicesData.map((service, index) => (
            <div key={index} className={style.mItems}>
              <p className={style.iheader}>{service.title}</p>
              <img
                src={service.img}
                alt={service.title}
                className={style.icon}
              />
              <div className="my-[3rem] space-y-4 mx-6">
                {service.subTexts.map((subText, index) => (
                  <div key={index} className={style.row}>
                    <img className={style.rowicon} alt="" src="Pointer.png" />
                    <p className={style.ritext}>{subText}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
<div className="my-12">

</div>
        {/* <div className="flex flex-row bg-[#EF4747] w-full rounded-sm items-center px-8 py-8 my-16 justify-between">
          <div className="flex flex-col">
            <p className="text-white text-2xl font-bold font-nunito ">
              20% - Premium Platinum membership only!
            </p>{' '}
            <p className="text-white text-sm font-light font-nunito mt-2 w-4/6">
              Join the Bulwark Club and get amazing discounts for first time
              rentals and renewals. Also get gifts and incentives from our
              periodic freebies.
            </p>
          </div>
          <div className="border border-white p-2 items-center rounded-sm">
            <div>
              <Link to="/contact">
                <p className="text-white text-lg font-light font-nunito mt-2">
                  {' '}
                  Contact Us Now
                </p>
              </Link>
            </div>
          </div>
        </div> */}
        <div className="flex flex-row">
          {data.map((item) => (
            <div className="col mx-4 w-72" key={item.id}>
              <div className="relative">
                <img
                  className="rounded-se-lg h-48 w-full"
                  src={item.imageSrc}
                  alt=""
                />
                <div className="absolute top-0 left-0 w-full h-auto flex items-center justify-center bg-[#D0D2D6] bg-opacity-50">
                  <p className="text-midnight text-lg font-nunito font-bold">
                    {item.title}
                  </p>
                </div>
              </div>

              <div className="flex flex-col h-auto items-center rounded-es-lg shadow-lg text-center">
                <p className="text-midnight text-sm font-nunito font-light bg-[#F0F0F0] py-1 w-full">
                  {item.dimensions}
                </p>
                <p className="text-midnight text-sm font-nunito font-light bg-[#FFF] w-full py-1">
                  {item.description}
                </p>
                <p className="text-midnight text-sm font-nunito font-light bg-[#F0F0F0] py-1 px-1">
                  {item.additionalInfo}
                </p>
                <Link to="/contact">
                  <div className="bg-midnight w-48 text-white text-sm font-bold font-nunito rounded-sm py-2 mt-8 mb-4">
                    {item.buttonText}
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Services
