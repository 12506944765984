import React, { useState, useEffect } from 'react';

export const useDisplayNavState = () => {
  const [displayNav, setDisplayNav] = useState(false);

  useEffect(() => {
    let prevScrollPos = window.pageYOffset;

    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;

      if (currentScrollPos > prevScrollPos && displayNav) {
        setDisplayNav(false);
      }

      prevScrollPos = currentScrollPos;
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [displayNav]);

  return { displayNav, setDisplayNav };
};
