import React from 'react'
import Navbar from './components/Navbar'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import About from './pages/About'
import Services from './pages/Services'
import Meet from './pages/Meet'
import Contact from './pages/Contact'

function App() {
  return ( 
    <>
    <Navbar/>
    <Routes>
      <Route path="" element={<Home />} />
      <Route path="about" element={<About />} />
      <Route path="services" element={<Services/>} />
      <Route path="meet-the-team" element={<Meet/>} />
      <Route path="contact" element={<Contact/>} />
    </Routes>
    </>
  )
}

export default App