import React from 'react'
import Footer from '../components/Footer'
import { Tooltip, Button } from '@material-tailwind/react'

function Meet() {
  const style = {
    wrapper:
      'sm:px-12 sm:py-16 sm:w-full sm:h-auto sm:bg-white sm:flex sm:flex-col sm:justify-evenly sm:items-center     px-8 py-4 w-full h-auto bg-white flex flex-col justify-evenly items-center ',
    headerItem: 'sm:items-center sm:mb-24 items-center mb-8',
    header:
      'sm:pl-2 sm:text-4xl sm:font-nunito sm:font-bold sm:text-midnight  pl-8 text-lg font-nunito font-bold text-midnight',
    middle:
      'sm:grid sm:grid-cols-3  sm:gap-x-24 sm:gap-y-16 sm:flex sm:justify-between sm:items-center    grid grid-cols-2 gap-x-8 gap-y-8 flex justify-between items-center ',
    topItem: 'flex flex-col',
    icon: 'sm:w-32 sm:h-32 w-24 h-24 rounded-xl',
    l: 'sm:w-72 w-64',
    row: 'flex flex-row space-x-4 justify-between items-center',
    title:
      'sm:font-nunito sm:text-midnight sm:text-xs sm:font-bold sm:pt-4    font-nunito text-black text-[9px] font-bold pt-4',
    subtitle:
      'sm:font-nunito sm:text-midnight sm:text-xs font-nunito text-midnight text-[8px]',
    line: 'sm:w-72 sm:items-center w-48 items-center',
    bottom:
      'flex flex-col space-x-4 bg-[#E9F0F3] mt-4 items-center justify-center content-center shadow-lg rounded-xl p-4',
  }

  const management = [
    {
      name: 'ABIOLA MONEHIN',
      role: 'Chief Operating Officer',
      image: 'image 8.jpg',
      content: 'Abiola Monehin is a graduate of Mass communication She started her career with mediareach OMD where she worked as a media officer And moved on to United Bank for Africa where she worked at the marketing and communication for seven years. Worked with westoil as the personal assistant to the ceo and moved on to bulwark. Where began as a dedicated and hardworking staff of the marketing department. As goal getter, driven with passion for success earned her the current position of the COO. She has been able to reposition the company to an enviable height till date.',
    },
    {
      name: 'AKANLE ABOWABA OLANREWAJU',
      role: 'Chief Security Officer',
      image: 'image 7.jpeg',
      content: 'Akanle Abowaba Olanrewaju is a multi-talented Nigerian individual with a strong passion for learning and technology. Born in Itapa Ekiti, in Ekiti State. He completed his primary and secondary education with good grades and holds a bachelor\'s degree in Demography and Social Statistics from Obafemi Awolowo University Ife, an Advance Diploma in Computers Science, Specialist Professional in Corporate and Industrial Security Management, Advance Diploma in Security Operations and Management, Postgraduate Diploma in Theology and Master in Security and Intelligence Studies from the University of Lagos. He is currently a Security Expert. Aside from his professional endeavors, Akanle is also an artist at heart. He possesses a creative spirit that finds expression in various artistic forms. Akanle\'s passion for the arts has led them to explore graphic designs, music, and writing, among other mediums. The artistic pursuits provide a unique outlet for self-expression and allow him to connect with others on a deeper level. With a diverse range of skills and a relentless drive for success. His interests range from entrepreneurship to environmental sustainability, and his infectious enthusiasm and indomitable spirit make him an influential figure in the field of technology and a beacon of inspiration for aspiring professionals worldwide.',
    },
    {
      name: 'PAUL  OHONEME',
      role: 'Head of Business Development',
      image: 'image 9.jpg',
      content: 'Paul Ohoneme is a highly accomplished professional with a diverse background in estate management and digital marketing. Born and raised in Nigeria, he graduated from the Federal Polytechnic Auchi in 2008, earning his degree in estate management. He is a member of the International Facility Management Association (IFMA) With a passion for the real estate industry, Paul embarked on his career as an estate manager immediately after graduation. For a remarkable span of eight years, he honed his skills and gained valuable experience in the field. His dedication and expertise enabled him to excel in various aspects of estate management, including property valuation, leasing, sales, and property development.  In 2018, Paul\'s career took a new direction when he assumed the role of Head of Business Development at Bulwark Vault and Safes Deposits Limited. In this capacity, he played a crucial role in driving the company\'s growth and expansion. Leveraging his extensive real estate market knowledge, he developed effective strategies to identify new business opportunities, foster partnerships, and enhance client relationships. Recognizing the importance of digital marketing in today\'s business landscape, Paul sought to expand his skill set. He became a Google-certified digital marketer, equipping himself with the latest techniques and strategies to optimize online marketing campaigns. Additionally, he obtained a certification as LinkedIn-certified Content Marketer for social media, further enhancing his expertise in content creation and social media marketing.',
    },
  ]

  const directors = [
    {
      name: 'OLUSEGUN OLUSANYA',
      role: 'Chairman',
      image: 'image 6.png',
      content:
        'Mr. Olusegun Olusanya is a graduate of London School of Economics and a professional accountant. He is a seasoned civil servant and banker of repute. Before his appointment to the Board of Triple Gee & Company Plc in 1999, Mr. Olusegun Olusanya served as an Executive Director of Afribank, Nigeria Merchant Bank and Union Bank of Nigeria Plc and was also the Chairman, Board of Directors of National Bank. He is a board member of many companies, including Benue Cement Company Plc, Gboko and IPI Co. Ltd.',
    },
    {
      name: 'GBOYEGA FATIMILEHIN',
      role: 'Executive Vice Chairman',
      image: 'image 7.png',
      content:
        'He is a co-founding partner of Diya Fatimilehin & Co. He graduated with a Bachelor of Science (honors) degree from the then University of Ile-Ife (now Obafemi Awolowo University) in 1979. He started his career as a trainee surveyor before joining Mr. M.K. Diya to found Diya Fatimilehin & Co. He is a board member of several organizations and a key member of many professional institutions including the Nigerian Institution of Estate Surveyors and Valuers (NIESV), Nigerian Institute of Management (NIM), Institute of Directors (London), Senior Certified Valuer of the International Real Estate Institute USA (SCV) and the Institute of Directors (Nigeria).',
    },
    {
      name: 'PRINCE ADESEGUN OGUNLEWE',
      role: 'Director',
      image: 'image 2.png',
      content:
        'Prince Adesegun Olusola Ogunlewe graduated from the University of Ife (now Obafemi Awolowo University) where he obtained the Bachelor of Social Science (Honours) Degree in Sociology and also a Post Graduate Diploma in Public Administration (PGDPA) Programme. He has served in civil service as an Administrative officer, Secretary to Lagos State Road Safety Corporation, Lagos State Ferry Service Corporation, Joint Secretary to the Lagos State Executive Council and the Executive Secretary, Land Use and Allocation Committee and as a Permanent Secretary to the Public Service Office, Civil Service Commission, Parastatal Monitoring Office and Ministry of Establishments and Training. He was elevated to Head of Service and served meritoriously until his statutory retirement. He is a Fellow of the Insitutue of Public Administration of Nigeria (IPAN), Nigerian Institute of Training and Development (NITAD) and Chartered Institute of Purchasing and Supply Management of Nigeria (CIPSMAN). He is also a Patron of the Association for the Eradication of Corruption in Nigeria (ASSERCORN).',
    },
    {
      name: 'CHRIS ABRAHAM',
      role: 'Director',
      image: 'image 3.png',
      content:
        'He started his career with D. O. Dafinone & Co (Chartered Accountants) and has worked with Nigeria International Bank Limited as a Manager of Internal Control, Guaranty Trust Bank as a pioneer staff, Gamji Bank Plc (later rebranded as International Trust Bank Plc) as Chief Inspector/Group Head of Internal Control and Eagle Bank Plc as Chief Inspector/Head of Internal Control, Group Head of Risk Management and Credit Control. He has over 14 years in the banking industry. Mr. Abraham graduated from the Nigerian Law School and was called to the Bar as a Barrister & Solicitor of the Supreme Court of Nigeria in 2005. He is an Alumnus of the Lagos Business School. ',
    },
    {
      name: 'AKIN AKINTOYE',
      role: 'Director',
      image: 'image 4.png',
      content:
        'Akin Akintoye is the Managing Partner and Head of Chambers in the Lagos office of Alliance Legal. He holds a Diploma in Law Certificate, an LLB (Hons) and LLM (Masters in Law) degrees from the University of Lagos, Akoka Nigeria. He graduated from the Nigerian Law School and was called to the bar as a Barrister & Solicitor of the Supreme Court of Nigeria in 1978 and also practices as a solicitor in England. He is a director of several companies in Nigeria and the Chairman of New Prudential Mortgage Bank Limited, Lagos. An Alumnus of the Lagos Business School, Mr. Akintoye has over 32 years of experience in Legal Practice. He has a special bias for multilateral contract negotiations and documentation. ',
    },
    {
      name: 'CHIEF OLU FALOMO',
      role: 'Director',
      image: 'image 5.png',
      content:
        'He has been President of Council and a member of several Advertising Professional bodies; Association of Advertising Agencies of Nigeria (AAAN), Past President, Nigeria Chapter of International Advertising Association (IAA), Advertising Practitioner Council of Nigeria (APCON), council member from 1989-2003. He was appointed chairman of APCON 1999 and served for four years. He was also a member of the World Board of Directors of the International Advertising Association (IAA) from 1990 -1994. He is the second elected Fellow of the Registered Practitioners in Advertising in Nigeria (FRPA), a Director of many companies and Chairman of Reckitt Benkiser and Locke International Group of Companies.',
    },
    {
      name: 'LANRE FATIMILEHIN',
      role: 'Director',
      image: 'image 10.jpeg',
      content: 'Lanre Fatimilehin  is a Director at Trillium Real Estate Partners, a Nigerian real estate services firm and an affiliate of Jones Lang LaSalle(JLL).He joined JLL in 2018. He was responsible for delivering the whole suite of JLL’s services to occupiers, developers and investors within West Africa. Lanre has led several tenant representation processes for multi national firms.Prior to this, Lanre was the Acting CEO of Africa Real Estate Investment & Asset Management (ARIA), the asset management subsidiary of the Persianas Group, a developer of commercial real estate in Nigeria.He was a Principal in the Corporate & Institutional Clients team at Standard Chartered Bank Nigeria where he provided financing solution s to Nigerian corporates and real estate firms. He was previously responsible for the Debt Capital Markets business of Standard Chartered in West Africa, raising bonds for corporates and sub nationals. Lanre began his career within the Investment Banking at Goldman Sachs International in London where he was an Analyst and then an Associate in the European Structured Finance Group. At Goldman Sachs, he executed various construction and development financings for developers in the UK and the US. Lanre has 14 years of experience in the capital markets (across the EMEA region) and has spent the last 8 years structuring, executing and financing real estate transactions in West Africa. Lanre is a member of the Royal Institution of Chartered Surveyors and holds a MPhil in Real Estate Finance from the University of Cambridge.',
    },
  ]
  return (
    <>
      <div className={style.wrapper}>
        <div className={style.headerItem}>
          <span className={style.header}>Meet The Team</span>
          <img className={style.line} alt="" src="line.png"></img>
        </div>

        <div>
          <span className="sm:text-midnight sm:text-lg sm:font-nunito sm:font-bold text-midnight text-lg font-nunito font-bold ">
            Board of Directors
          </span>
          <div className={style.middle}>
            {directors.map((director, index) => (
              <Tooltip
                key={index}
                className={style.l}
                content={director.content}
              >
                <div className={style.bottom}>
                  <img src={director.image} className={style.icon} alt="" />
                  <span className={style.title}>{director.name}</span>
                  <span className={style.subtitle}>{director.role}</span>
                </div>
              </Tooltip>
            ))}
          </div>
        </div>
        <div className="sm:mt-12 mt-12 sm:justify-items-start sm:items-center">
          <span className="text-midnight text-lg font-nunito font-bold">
            Management Team
          </span>
          <div className={style.middle}>
            {management.map((team, index) => (
              <Tooltip key={index} className={style.l} content={team.content}>
                <div className={style.bottom}>
                  <img src={team.image} className={style.icon} alt="" />
                  <span className={style.title}>{team.name}</span>
                  <span className={style.subtitle}>{team.role}</span>
                </div>
              </Tooltip>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Meet
