import { React, useEffect, useState } from 'react'
import Footer from '../components/Footer'
import { Button, Textarea, Input, Card } from '@material-tailwind/react'
import Swal from 'sweetalert2'


function Contact() {
  const style = {
    wrapper:
      'sm:px-12 sm:py-16 sm:w-full sm:h-auto sn:bg-white sm:flex sm:flex-col sm:justify-evenly sm:items-center   px-8 py-4 w-full h-auto bg-white flex flex-col items-center  ',
    headerItem:
      ' sm:flex sm:flex-col sm:items-center sm:mb-24 flex flex-col items-center mb-12',
    header:
      'sm:pl-2 sm:text-4xl sm:font-nunito sm:font-bold sm:text-midnight  pl-2 text-lg font-nunito font-bold text-midnight',
    middle:
      'sm:px-12 sm:py-8 sm:flex sm:flex-row sm:my-0 sm:justify-between shadow-xl  sm:bg-[#E9F0F3] sm:h-auto sm:items-center sm:w-7/12 sm:rounded-xl  px-12 py-8 flex flex-row my-8 justify-between  bg-[#E9F0F3] items-center  rounded-xl',
    line: 'sm:w-64 w-32',
    subtitle:
      'sm:font-nunito sm:text-black  sm:text-xs sm:w-2/4 sm:mt-4 sm:leading-6   leading-5 mt-4 text-xs text-center text-black font-nunito',
    mItems:
      'flex flex-col h-auto w-auto bg-[#E9F0F3] px-8 rounded-lg items-center justify-evenly ',
    iheader: 'font-nunito text-black text-xl font-bold pt-4',
    row: 'text-[#282938] flex flex-row items-center space-x-4',
    icon: 'w-64 mt-16',
    rowicon: 'w-4',
    ritext: 'w-72',
    btn: 'bg-midnight text-sm font-nunito mb-8 rounded-2xl',
  }

  const [formState, setFormState] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  })

  useEffect(()=>{

  },[])

  const changeHandler = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value })
  }
  const [submitting, setSubmitting] = useState(false)
  const [messageSent, setMessageSent] = useState(false)
  const [name, setName] = useState('')
  const [subject, setSubject] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const sendEmail = (event) => {
    event.preventDefault()
    const formData = { name, email, message,subject }
    console.log(formData)
    fetch('/api/contact', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        setMessageSent(true)
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  //.then((message) => {
  //   alert(message)
  //   Swal.fire({
  //     icon: 'success',
  //     title: '',
  //   })
  // })

  return (
    <>
      <div className={style.wrapper}>
        <div className={style.headerItem}>
          <span className={style.header}>Contact Us</span>
          <img className={style.line} alt="" src="line.png"></img>
          <span className={style.subtitle}>
            Just anytime it occurs to you that you need to get in touch with us,
            we are just a call away. We have a relationship manager attached to
            you, to attend to your needs as quickly as possible.
          </span>
        </div>

        <div className={style.middle}>
          <form
            className="sm:w-full w-full sm:space-y-4 space-y-8"
            onSubmit={sendEmail}
          >
            <div>
              <span className="pb-4 text-sm font-medium font-nunito text-black">
                Name
              </span>
              <Input
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                size="lg"
                label="Enter your name"
              />
            </div>
            <div>
              <span className="pb-4 text-sm font-medium font-nunito text-black">
                Email
              </span>
              <Input
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                size="lg"
                label="Enter your email"
              />
            </div>
            <div>
              <span className="pb-4 text-sm font-medium font-nunito text-black">
                Subject
              </span>
              <Input
                name="subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                size="lg"
                label="Provide Context"
              />
            </div>
            <div>
              <span className="pb-4 text-sm font-medium font-nunito text-black">
                Message
              </span>
              <Textarea
                label="Write a mesage"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>

            <Button type="submit" disabled={submitting} className={style.btn}>
              {submitting ? 'Submitting...' : 'Submit'}
            </Button>
            {messageSent && <p>Message sent successfully!</p>}
          </form>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Contact
