import React from 'react'
import { Link } from 'react-router-dom'
import { BiMenuAltRight } from 'react-icons/bi'
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa'

function Footer() {
  const style = {
    wrapper: 'sm:mt-8 mt-4',
    topleft: 'sm:flex sm:flex-col flex flex-col pt-4',
    logo: 'flex flex-row w-6 space-x-2 items-center',
    topright: 'sm:flex sm:flex-col flex flex-col pb-4',
    topleftl:
      'sm:bg-midnight sm:flex sm:flex-row sm:px-24 bg-midnight flex flex-row pt-4',
    topleftlitem: ' bg-[#B8E5EC] flex flex-col space-y-1 py-4 px-4',
    tltext:
      'sm:text-2xl sm:text-white sm:font-bold sm:font-bold  text-2xl text-white font-bold text-[12px]',
    tlsmall:
      'sm:leading-6 sm:w-60 sm:text-[10px] sm:text-white sm:font-light sm:mt-4  leading-6 w-60 text-[10px] text-white font-light',
    top:
      'sm:bg-midnight  sm:flex sm:flex-row  sm:justify-between sm:py-8 sm:px-24 bg-midnight  flex flex-col  space-y-8 items-center',
    tlbottom: ' h-12 bg-[#B8E5EC]',
    copy:
      'sm:font-light sm:font-nunito sm:text-xs sm:flex sm:flex-row sm:items-center  font-light font-nunito text-xs flex flex-row items-center mb-2',
    bottoml:
      'sm:font-light sm:font-nunito sm:text-sm sm:text-midnight sm:hover:text-midnight sm:space-x-8 sm:justify-between      flex flex-row items-start items-start font-light font-nunito text-[8px] text-midnight space-x-8 hover:text-midnight mb-2',
    bottom:
      ' sm:flex sm:flex-row sm:p-4 sm:font-nunito sm:text-sm sm:items-center sm:font-light sm:hover:text-midnight sm:justify-between items-center font-light text-xl flex flex-col justify-between  bg-[#fff]',
      media:"flex flex-row space-x-8 mt-3",
      icons:'sm:text-lg text-white text-xs'
  }
  return (
    <div class={style.wrapper}>
      <div class={style.top}>
        <div class={style.topleft}>
          <div class={style.logo}>
            <img src="logo.png" />
            <h1 class={style.tltext}>Bulwark</h1>
          </div>

          <span class={style.tlsmall}>
            Many call us the bank for valuables, we prefer to be seen as a
            partner that loves your peace
          </span>
        </div>

        <div class={style.topright}>
          <h1 class={style.tltext}>Let's Talk!</h1>
          <div>
            <h1 class={style.tlsmall}>
              At Bulwark, we don’t sleep , we work round the clock, this means
              whenever you need your valaubles we will be there for you all day
            </h1>
            <div class={style.media}>
              <a
                className={style.copy}
                rel="noreferrer"
                href="https://web.facebook.com/BulwarkVault/?_rdc=1&_rdr"
                target="_blank"
              >
                <FaFacebook className={style.icons}></FaFacebook>
              </a>
              <a
                className={style.copy}
                rel="noreferrer"
                href="https://instagram.com/bulwarksafes?igshid=NGExMmI2YTkyZg=="
                target="_blank"
              >
                <FaInstagram  className={style.icons}/>
              </a>
              <a
                className={style.copy}
                rel="noreferrer"
                href="https://www.linkedin.com/company/bulwark-vault-safes-deposits-limited"
                target="_blank"
              >
                <FaLinkedin className={style.icons}/>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class={style.topleftl}>
      <div class="sm:flex sm:flex-col sm:justify-item-center sm:w-2/6  bg-[#B8E5EC] space-y-1 py-4 px-4  flex flex-col justify-item-center w-2/6  bg-[#B8E5EC] space-y-1 py-4 px-4">
          <hi class="sm:text-midnight sm:text-lg sm:font-nunito sm:font-bold text-[10px] font-bold font-nunito">
            Our Address:
          </hi>
         
            <span class="sm:text-[#53595C] sm:text-sm sm:font-nunito  font-nunito text-[8px] text-[#53595C] ">39 Alfred Rewane road, MullinerTowers, Old NNPC Building, Ikoyi, Lagos</span>
           
          
    
          
        </div>
        <div class={style.topleftlitem}>
          <h1 class="sm:text-midnight sm:text-lg sm:font-nunito sm:font-bold text-[10px] font-bold font-nunito">
            Email us at
          </h1>
          <span class="text-[#53595C] sm:font-nunito sm:text-sm font-nunito text-[8px] ">
          enquiries@bulwarkvault.com
          </span>
        </div>
     
        <div class={style.topleftlitem}>
          <hi class="sm:text-midnight sm:text-lg sm:font-nunito sm:font-bold text-[10px] font-bold font-nunito">
            Call Us:
          </hi>
          <div class="sm:flex sm:flex-row sm:space-x-2 sm:items-center sm:justify-center flex flex-col  items-center">
            <span class="sm:text-[#53595C] sm:text-sm sm:font-nunito  font-nunito text-[8px] text-[#53595C] ">08073477926</span>
            <span class="sm:text-[#53595C] sm:text-sm sm:font-nunito  font-nunito text-[8px] text-[#53595C]">09090386353</span>
         
          
    
          </div>
        </div>
      </div>
      <div class={style.bottom}>
        <div class={style.bottoml}>
          <Link to="/">
            {' '}
            <span>Home</span>{' '}
          </Link>
          <Link to="/about">
            {' '}
            <span>About</span>{' '}
          </Link>
          <Link to="/services">
            {' '}
            <span>Services</span>{' '}
          </Link>
          <Link to="/meet">
            {' '}
            <span>Meet The Team</span>{' '}
          </Link>
          <Link to="/contact">
            <span>Contact</span>
          </Link>
        </div>
        <a
          className={style.copy}
          rel="noreferrer"
          href="https://www.transformafricalimited.com"
          target="_blank"
        >
          <img className="w-8 h-8 item-center" alt="" src="ta.png" />
          <span className="text-[8px] font-nunito">
            Powered By TransformAfrica
          </span>
        </a>
      </div>
    </div>
  )
}

export default Footer
