const servicesData = [
    {
        title: "ELECTRONIC RECORDS",
        img: "disc.png",
        subTexts: ["Hard drives/DVDs/ Tapes/Computer Disks", "Digital documents.","Special codes and Password","Credit Cards","Certificates"]
    },
    {
        title: "NON-ELECTRONIC RECORDS",
        img: "pass.png",
        subTexts: ["Passport", "Power of attorney, wills & trusts", "Insurance policies", "Tax returns"]
    },
    {
        title: "VALUABLES",
        img: "gold.png",
        subTexts: ["Rare coins", "Collectibles", "Limited Edition Wrist watches", "Jewellery-gold/diamonds/ ruby/platinum etc", "High value tools e.g. diamond-tipped drill bits"]
    }
]

export default servicesData