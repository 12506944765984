import React from 'react'
import Footer from '../components/Footer'

function About() {
  const style = {
    wrapper:
      'sm:px-12 sm:py-16 sm:w-full sm:h-auto sm:bg-white sm:flex sm:flex-col sm:justify-evenly sm:items-center px-8 py-4 w-full h-auto bg-white flex flex-col justify-evenly items-center ',
    headerItem: 'sm:items-center sm:mb-24 items-center mb-12',
    header:
      'sm:pl-12 sm:text-4xl sm:font-nunito sm:font-bold sm:text-midnight  pl-2 text-lg font-nunito font-bold text-midnight',
    middle:
      'sm:grid sm:grid-cols-2 sm:gap-x-32 sm:gap-y-12 sm:flex sm:justify-between sm:items-center    grid grid-cols-2 gap-x-8 gap-y-4 flex justify-between items-center',
    topItem: 'flex flex-col',
    icon: 'sm:w-14 sm:h-14 w-8 h-8',
    row: 'flex flex-row space-x-4 justify-between items-center',
    top: 'sm:flex sm:flex-col',
    title:
      'sm:font-nunito sm:text-black sm:text-lg sm:font-bold  font-nunito text-black text-[12px] font-bold',
    subtitle:
      'sm:font-nunito sm:text-midnight sm:text-sm sm:text-base sm:w-72 font-nunito text-midnight text-[8px] w-24',
    bottom: 'flex flex-col space-x-4 justify-around mt-32 items-center',
    bottomItems:
      'sm:flex sm:flex-row sm:space-x-4 sm:justify-around flex flex-row space-x-4 space-y-4 items-center',
    bicon: 'w-24 h-24',
    otheri: 'sm:w-32 sm:h-32 w-12 h-12',
    line: 'sm:w-64 sm:items-center w-24 items-center',
    brow:
      'sm:flex sm:flex-row sm:space-x-8 sm:items-center flex flex-col  items-start',
  }

  return (
    <>
      <div className={style.wrapper}>
        <div className={style.headerItem}>
          <span className={style.header}>About Us</span>
          <img className={style.line} alt="" src="line.png"></img>
        </div>

        <div className="sm:w-full sm:px-72">
          <div className="sm:flex sm:w-full sm:justify-between sm:items-center mb-12">
            <div className="sm:flex sm:flex-col sm:justify-items-start sm:space-y-4 sm:w-72   flex flex-col">
              <span className="sm:text-4xl sm:text-[#282938] sm:text-black font-nunito font-bold">
                Bulwark is here!
              </span>
              <span className="sm:text-sm sm:text-[#282938] font-nunito text-xs text-[#282938] font-nunito mb-8 mt-2 leading-4">
                Bulwark is here with all the sophisticated depositories
                facilities to keep all your valuables as safe as your life{' '}
              </span>
            </div>
            <img alt=" " src="s.jpg" className="w-96 h-96"></img>
          </div>

          <div className="sm:bg-[#B8E5EC] sm:h-72 bg-[#B8E5EC] h-auto">
            <div className="sm:flex sm:flex-row sm:w-full sm:justify-between sm:items-center flex flex-col w-full">
              <div className="sm:flex sm:flex-col sm:justify-items-start sm:space-y-2 sm:w-2/5 sm:p-12  flex flex-col p-8">
                <span className="sm:text-sm sm:text-[#282938] font-nunito text-sm text-[#282938] mb-2">
                  Who we are
                </span>
                <span className="sm:text-2xl sm:text-[#282938] sm:text-black text-xl  text-[#282938] font-nunito font-bold">
                  Our Values
                </span>
                <span className="sm:text-sm sm:text-[#282938] font-nunito text-[#282938] text-xs">
                  We understand the value of privacy in all our dealings,
                  therefore every client is given confidential and highly
                  exclusive service, your assets are strictly protected and no
                  one else can have access to your box. When we say we maintain
                  and confidentiality, we mean it!!
                </span>
              </div>
              <div className="sm:flex sm:flex-col sm:justify-items-start sm:space-y-2 sm:w-2/5 sm:p-12  flex flex-col p-8">
                <span className="sm:text-2xl sm:text-[#282938] sm:text-black font-nunito font-bold text-xl">
                  Our Promise
                </span>
                <span className="sm:text-sm sm:text-[#282938] font-nunito text-[#282938] text-xs">
                  We know you can’t entertain stories that is why we protect
                  your valuables with the most sophisticated depository
                  facilities and best practices around the world to ensure 100%
                  safe-keeping of your valuables.
                </span>
              </div>
            </div>
          </div>
          <img className="h-48 w-full box-fit" src="huge.jpg" alt=""></img>
        </div>

        <div className="sm:flex sm:flex-col sm:w-2/4 sm:m-24 flex flex-col sm:p-12 my-8">
          <span className="sm:text-2xl sm:text-[#282938] sm:text-black  text-[#282938] font-nunito font-bold text-black text-lg">
            Our project
          </span>
          <span className="sm:text-sm sm:text-[#282938] font-nunito sm:mt-4 text-[#282938] text-xs mt-2">
            The history of safe keeping and depository service comes from the
            need of wealthy people to store their valuables for safekeeping.
            Safekeeping goes back to the time of the Pharaohs when they placed
            their valuables in pyramids for security. The first "modern" safe
            deposit box was one made for an individual with lockable wood
            drawers with the use of vinegar as built-in security in the safe
            keeping of documents. The industry has advanced to metal safes with
            dual control locks. In Nigeria, Safe keeping and Depository services
            was first practiced by some financial institutions for select
            customers before the entry of a private vault depository over 25
            years ago. Awareness has not been fully created in the country
            regarding Safe depository services. Bulwark is Nigeria's foremost
            private safe depository incorporating cutting edge, state-of-the-art
            security systems and protocols while providing round the clock
            accessibility to its clients.
          </span>
        </div>

        <div className={style.middle}>
          <div className={style.row}>
            <img className={style.icon} src="pro.png" alt=""></img>
            <div className={style.topItem}>
              <span className={style.title}>Protection</span>
              <span className={style.subtitle}>
                We protect your valuables with the most sophisticated depository
                facilities and best practices around the world; to ensure 100%
                safe-keeping of your valuables.
              </span>
            </div>
          </div>
          <div className={style.row}>
            <img className={style.icon} src="conf.png" alt=""></img>
            <div className={style.topItem}>
              <span className={style.title}>Confidential</span>
              <span className={style.subtitle}>
                We understand the value of privacy in all our dealings,
                therefore every client is given confidential and highly
                exclusive service
              </span>
            </div>
          </div>
          <div className={style.row}>
            <img className={style.icon} src="24.png" alt=""></img>
            <div className={style.topItem}>
              <span className={style.title}>24 / 7 Service</span>
              <span className={style.subtitle}>
                Our Vault service is available 24 / 7 with operations hours even on holidays so that you can access your items anytime you need them.
              </span>
            </div>
          </div>
          <div className={style.row}>
            <img className={style.icon} src="priv.png" alt=""></img>
            <div className={style.topItem}>
              <span className={style.title}>Privacy</span>
              <span className={style.subtitle}>
                Our facility entry and exit procedures have been designed to
                maximize protection of your valuables and irreplaceable
                documents. Access to your safe deposit box can only be from the
                authorized owner. Unlike the Banks and other financial
                institutions, we are a private facility where security and
                safety of your valuables is matched by absolute and total
                privacy.{' '}
              </span>
            </div>
          </div>
        </div>

        <div className={style.bottom}>
          <div className={style.header}>Items we secure</div>
          <div className={style.bottomItems}>
            <div className={style.brow}>
              <img className={style.otheri} src="disc.png" alt=""></img>
              <ul class="list-disc">
                <li className={style.subtitle}>
                  Hard drives/DVDs/ Tapes/Computer Disks.
                </li>
                <li className={style.subtitle}>Digital documents.</li>
              </ul>
            </div>
            <div className={style.brow}>
              <img className={style.otheri} src="pass.png" alt=""></img>
              <ul class="list-disc">
                <li className={style.subtitle}>Passports.</li>
                <li className={style.subtitle}>
                  Power of attorney, wills & trusts.
                </li>
                <li className={style.subtitle}>Insurance policies.</li>
                <li className={style.subtitle}>Tax returns.</li>
              </ul>
            </div>
            <div className={style.brow}>
              <img className={style.otheri} src="gold.png" alt=""></img>
              <ul class="list-disc">
                <li className={style.subtitle}>Rare Coins.</li>
                <li className={style.subtitle}>Collectibles.</li>
                <li className={style.subtitle}>Limited Edition Wristwatches</li>
                <li className={style.subtitle}>
                  High value tools e.g. diamond-tipped drill bits.
                </li>
                <li className={style.subtitle}>
                  Jewellery-gold /diamonds/ ruby/platinum etc.
                </li>
                <li className={style.subtitle}>Other irreplaceable items.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default About
